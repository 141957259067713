import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

import perk1Img from "../images/speed.svg"
import perk2Img from "../images/piggy-bank.svg"
import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

export default () => (
  <>
    <Layout>
      <Banner />
      <TextBlock
        id="about"
        title="Developing a new business is never easy, but Startup Seo Company is here to help"
        paragraph="With a super-fast Gatsby powered website you have the perfect platform to get your idea off the ground. Its the perfect template to help turn your idea into a reality."
      >
        <Link to="perks" smooth={true} duration={500}>
          <Button label="Tell Me More" cta="Tell me more!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="perks"
        title="We have the tools to help you suceed"
        subtitle="Startup Seo Company will help you start and grow your business. When
        You will start to attract new customers and expand, you will see the benefits
        super-fast website on Gatsby"
      >
        <div className="flex-container trio-block">
          <Perk
            img={perk1Img}
            alt="Speedy"
            title="Speedy"
            content="Incredible site loading speed will help potential customers get to know your business faster"
          />
          <Perk
            img={perk2Img}
            alt="Affordable"
            title="Affordable"
            content="Large selection of tariff packages suitable for businesses with different budgets"
          />
          <Perk
            img={perk3Img}
            alt="Friendly"
            title="Friendly"
            content="Advisors who are available 24/7, all with expert knowledge"
          />
        </div>
      </TextBlockImg>
      <Packages
        id="packages"
        title="Our Packages"
        para="Choose the perfect solution for you. When working with projects with different budgets, Startup Seo Company can offer qualified help and expert advice"
      >
        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          <Package title="Standard">
            <ul>
              <li>
                <MdDone />Gatsby website
              </li>
              <li>
                <MdDone />
                SEO and SMM strategy
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                24/7 Support
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Mega" active={true}>
            <ul>
              <li>
                <MdDone />
                24/7 Support
              </li>
              <li>
                <MdDone />
                Dedicated Advisor
              </li>
              <li>
                <MdDone />
                Website, SEO and SMM strategy
              </li>
              <li>
                <MdDone />
                Market research, analysis of competitors
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
          <Package title="Super">
            <ul>
              <li>
                <MdDone />
                Website, SEO and SMM strategy
              </li>
              <li>
                <MdDone />
                Content plan for each social network
              </li>
              <li>
                <MdDone />
                Consultations and support
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package>
        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Contact Startup Seo Company today and see how we can help your business grow"
        subtitle="Every second counts when you want to start your new business. Leave a message about the start of the project and one of our consultants will contact you"
      />
    </Layout>
  </>
)
